<template>
  <v-container fluid>
    <v-layout row wrap v-if="!isLoading">
      <v-flex xs12 class="text-center mx-sm-8" mt-5>
        <h1>{{ $t(tutorialData.title) }}</h1>
        <attestation-test
          :testData="tutorialData.test"
          :testName="tutorialData.testName"
          v-if="showUserTest()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { findIndex, isEqual } from "lodash";
import { tutorials } from "../../helpers/constants";
import AttestationTest from "@/components/AttestationTest.vue";
import CustomButton from "@/components/CustomButton.vue";
export default {
  components: { AttestationTest, CustomButton },
  data() {
    return {
      testId: this.$route.params.testId,
      tutorialsList: tutorials,
      tutorialData: {},
      isLoading: true,
    };
  },
  created() {
    this.getTutorialData();
  },
  methods: {
    showUserTest() {
      return this.tutorialData && this.tutorialData.test;
    },
    getTutorialData() {
      const indexOfTutorial = findIndex(this.tutorialsList, (tutorial) =>
        isEqual(tutorial.id, this.testId)
      );
      if (indexOfTutorial > -1) {
        this.tutorialData = this.tutorialsList[indexOfTutorial];
      }
      this.isLoading = false;
    },
  },
};
</script>


<style scoped>
.v-btn ::v-deep .v-btn__content {
  white-space: normal;
  flex: auto;
}

.v-btn {
  height: 100% !important;
}
</style>
